import {
  fetch,
  fetchJson,
  getDefaultLanguage,
  getVersionHash,
  initBranding,
  ninjaReportError,
  setLanguage,
  setLocale,
  showApplicationFailedToLoadDialog,
  initializeMomentThresholds,
} from "js/includes/common/utils"
import thunk from "redux-thunk"
import setupReduxStore from "js/state/setupReduxStore"
import rootReducer from "js/state/reducers/rootReducer"
import { initializeSentry } from "js/includes/common/services/sentry"
import { initializeAlerts } from "js/includes/components/Alerts"
import { setValue } from "js/state/actions/common/value"
import { initTimers } from "js/state/actions/ticketing"
import { initializeMarketingPromo } from "js/includes/components/MarketingPromoPopup"

const initReduxStore = initialState => {
  setupReduxStore(rootReducer, initialState, ...[thunk])
}

const setLangAndLocale = (properties, appUserLanguage) => {
  const [language, locale] =
    !appUserLanguage || appUserLanguage === "browser-lang"
      ? [getDefaultLanguage(properties), window.navigator.language]
      : [appUserLanguage.toLowerCase(), appUserLanguage.toLowerCase()]

  return Promise.all([setLanguage(language), setLocale(locale)])
}

export const initApp = async ({ initialReduxState }) => {
  initReduxStore(initialReduxState)

  initializeAlerts()
  initializeMomentThresholds()
  initializeMarketingPromo()

  const [session, properties] = await Promise.all([
    fetch(`/ws/webapp/sessionproperties`, {
      redirectUserToLogin: false,
      useSessionPrefix: false,
    }),
    fetchJson(`/ws/properties?cacheKey=${getVersionHash()}`, { useSessionPrefix: false }),
  ])

  const environment = properties["application.environment"]
  window.store.dispatch(setValue("application.environment")(environment))
  window.store.dispatch(setValue("application.region")(properties["application.region"]))
  window.store.dispatch(setValue("application.recaptchaPublicKey")(properties["recaptcha.publicKey"]))
  window.store.dispatch(setValue("application.ticketingDomain")(properties["application.ticketing.domain"]))

  await initBranding()

  await initializeSentry({ environment })

  const authenticated = session.status === 200
  if (authenticated) {
    try {
      const sessionProperties = await session.json()
      const { appUserLanguage } = sessionProperties

      await setLangAndLocale(properties, appUserLanguage)

      window.store.dispatch({ type: "SET_SESSION", session: sessionProperties })

      window.store.dispatch(initTimers(sessionProperties.user.externalUid))

      return { authenticated, properties, sessionProperties }
    } catch (error) {
      ninjaReportError(error)
      showApplicationFailedToLoadDialog()
    }
  }
  return { authenticated, properties }
}
